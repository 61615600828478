.Loader {
    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .circle {
        color: #2975CC; 
        animation: rotate .8s linear infinite;
    }
}